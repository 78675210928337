<template>
  <b-card-code title="Add New Router Info">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group label="Route Name" label-for="route-name">
              <validation-provider
                #default="{ errors }"
                name="Route Name"
                rules="required"
              >
                <b-form-input
                  v-model="router_name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Route Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="IP Address" label-for="id-address">
              <validation-provider
                #default="{ errors }"
                name="IP Address"
                rules="required"
              >
                <b-form-input
                  v-model="ip_address"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter IP Address"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="IP Port" label-for="id-port">
              <validation-provider
                #default="{ errors }"
                name="Port"
                rules="required"
              >
                <b-form-input
                  v-model="router_port"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter IP Port"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="UserName" label-for="username">
              <validation-provider
                #default="{ errors }"
                name="UserName"
                rules="required"
              >
                <b-form-input
                  v-model="username"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Router User Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Password" label-for="password">
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-form-input
                  type="password"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Password Type"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Status" label-for="status">
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
              >
                <b-form-select v-model="selected" :options="status" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <b-button
                type="submit"
                variant="primary"
                class="mr-5"
                @click.prevent="validationForm"
              >
                Submit
              </b-button>

              <b-button type="reset" variant="primary"> Reset </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormSelect,
} from "bootstrap-vue";
import { required, email } from "@validations";
import useJwt from "@/auth/jwt/useJwt";
import Mixin from "@core/services/mixin";
import { checkRes } from "@core/services/helper";
import $ from "jquery";

export default {
  mixins: [Mixin],
  components: {
    BFormSelect,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      router_name: null,
      ip_address: null,
      router_port: null,
      password: null,
      username: null,
      selected: null,
      status: [
        { value: null, text: "Please select status" },
        { value: "1", text: "Active" },
        { value: "0", text: "Inactive" },
      ],
      emailValue: "",
      name: "",
      required,
      email,
      isp_id: null,
    };
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          useJwt
            .createRouter({
              isp_id: this.getIspId(),
              router_name: this.router_name,
              ip_address: this.ip_address,
              api_port: this.router_port,
              username: this.username,
              password: this.password,
              status: this.selected,
            })
            .then((response) => {
                if (checkRes(response.data.code)) {
                    this.$refs.simpleRules.reset();
                    this.router_name = "";
                    this.ip_address = "";
                    this.router_port = "";
                    this.username = "";
                    this.password = "";
                    this.$router
                        .push({name: "isp-router-create"})
                        .catch((error) => {
                            this.toastMessage("success", "Router Setting", response);
                        });
                } else {
                    if (response.data.status_message == "due_monthly_payment") {
                        this.$router
                            .push({name: "service-charge-package-list"})
                            .catch((error) => {
                                this.toastMessage("success", "Pay Monthly Bill", response);
                            });
                    } else {
                        this.$router
                            .push({name: "isp-router-create"})
                            .catch((error) => {
                                this.toastMessage("warning", "Router Setting", response);
                            });
                    }
                }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    getIspId() {
      return this.isp_id = $("#ispListId option:selected").val();
    },
  },
  mounted() {
    this.getIspId();
  },
};
</script>
